import * as XLSX from 'xlsx';

interface HeaderConfig {
  title: string;
  rowSpan?: number;
  colSpan?: number;
}

interface ExportConfig {
  mainHeaders: HeaderConfig[];
}

const useExportExcel = () => {
  const onExport = (data: any[], fileName: string, headerConfig: ExportConfig) => {
    const wb = XLSX.utils.book_new();
    const { mainHeaders } = headerConfig;

    

    const headerRows = [mainHeaders.map((h) => h.title)];
    const ws = XLSX.utils.aoa_to_sheet([...headerRows, ...data]);

    const calculateColumnWidths = () => {
      const allRows: (string | number)[][] = [...headerRows, ...data];

      return allRows[0].map((_cell: string | number, colIndex: number) => {
        const columnData = allRows.map((row) => {
          const cellValue = row[colIndex] ? row[colIndex].toString() : '';

          return cellValue.length;
        });

        const maxLength = Math.max(...columnData);

        return { wch: maxLength + 4 };
      });
    };

    ws['!cols'] = calculateColumnWidths();

    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    for (let R = headerRows.length; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        if (!cell) continue;

        if (typeof cell.v === 'number') {
          cell.z = '#,##0.00';
          if (Number.isInteger(cell.v)) {
            cell.z = '#,##0';
          }
        }
      }
    }

    const merges: XLSX.Range[] = [];
    mainHeaders.forEach((header, index) => {
      if (header.rowSpan === 2) {
        merges.push({
          s: { r: 0, c: index },
          e: { r: 1, c: index },
        });
      }

      if (header.colSpan && header.colSpan > 1) {
        merges.push({
          s: { r: 0, c: index },
          e: { r: 0, c: index + (header.colSpan - 1) },
        });
      }
    });

    if (merges.length > 0) {
      ws['!merges'] = merges;
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return { onExport };
};

export default useExportExcel;
