import React, { useEffect, useMemo } from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import useExportExcel from 'hooks/useExportExcel';
import DepositTransactionChip from 'components/shared/DepositTransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_TIME_FORMAT, EMPTY_CONTENT } from 'constants/common';
import { ROUTES } from 'constants/router';
import { useDirection } from 'hooks';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import {
  getListDepositTransactionInBuildingPagingAction,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, isAllow, showData } from 'utils/common';
import { PERMISSION } from 'constants/permission';
import { DEPOSIT_TRANSACTION_STATUS } from 'enums';
import moment from 'moment';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'invoice-code',
  'booking-code',
  'status',
  'floor',
  'room',
  'collect',
  'expenses',
  'payment-method',
  'creation-date',
  'executor',
  'payer',
];

const DepositTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useSelector(propertySelector);
  const { listDepositTransactionInBuildingPaging } = useSelector(transactionSelector);
  const { goTo } = useDirection();
  const { onExport } = useExportExcel();

  useEffect(() => {
    dispatch(
      getListDepositTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  const transactionTableRows = useMemo(() => {
    return listDepositTransactionInBuildingPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography color="textSecondary" variant="subtitle1">
              {showData(transaction.id)}
            </Typography>
          </Link>
        </Stack>,
        showData(transaction.depositInvoiceId),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography color="textSecondary" variant="subtitle1">
            {showData(transaction.depositInvoice?.booking?.id)}
          </Typography>
        </Link>,
        <DepositTransactionChip key={uniqueId()} status={transaction.status} />,
        <Typography key={uniqueId()}>
          {showData(transaction.depositInvoice?.booking?.infoRoom?.floor?.name)}
        </Typography>,
        <Link
          key={uniqueId()}
          onClick={goTo(
            `${ROUTES.ROOM.LIST}/${
              isAllow([PERMISSION.GET_ROOM_DETAIL])
                ? transaction.depositInvoice?.booking?.infoRoom?.room?.id
                : null
            }`,
          )}
          underline="none"
        >
          {showData(transaction.depositInvoice?.booking?.infoRoom?.room?.name)}
        </Link>,
        showData(transaction.isDeposit ? formatPrice(String(transaction.transactionAmount)) : null),
        showData(
          !transaction.isDeposit ? `-${formatPrice(String(transaction.transactionAmount))}` : null,
        ),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link
          key={uniqueId()}
          onClick={goTo(
            `${ROUTES.CLIENT.LIST}/${
              isAllow([PERMISSION.GET_USER_DETAIL]) ? transaction.paidUser?.userId : null
            }`,
          )}
          underline="none"
        >
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.paidUser?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listDepositTransactionInBuildingPaging.data, goTo]);

  const getTransactionTableRowsExcel = (data: any[]) => {
    return data.map((transaction) => {
      const status =
        transaction.status === DEPOSIT_TRANSACTION_STATUS.DEPOSIT
          ? 'deposit'
          : transaction.status === DEPOSIT_TRANSACTION_STATUS.REFUND
          ? 'deposit-refund'
          : transaction.status === DEPOSIT_TRANSACTION_STATUS.CONVERT_TO_INVOICE_REVENUE
          ? 'convert-to-invoice-revenue'
          : 'convert-to-other-revenue';

      const displayStatus = t(`transactionStatus.${status}`, EMPTY_CONTENT);

      return [
        showData(transaction.id),
        showData(transaction.depositInvoiceId),
        showData(transaction.depositInvoice?.booking?.id),
        displayStatus,
        showData(transaction.depositInvoice?.booking?.infoRoom?.floor?.name),
        showData(transaction.depositInvoice?.booking?.infoRoom?.room?.name),
        transaction.isDeposit ? formatPrice(String(transaction.transactionAmount)) : '',
        !transaction.isDeposit ? `-${formatPrice(String(transaction.transactionAmount))}` : '',
        showData(transaction.paymentMethod?.name),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        showData(transaction.createdBy?.fullName),
        showData(transaction.paidUser?.fullName),
      ];
    });
  };

  const headerConfig = {
    mainHeaders: TRANSACTION_TABLE_HEADER.map((title) => ({
      title: t(title),
    })),
  };

  const handleExportExcel = () => {
    const transactionTableRowsExcel = getTransactionTableRowsExcel(
      listDepositTransactionInBuildingPaging.data,
    );

    const formattedDate = moment().format('DD_MM_YYYY_HH_MM_SS');

    const fileName = `Deposit_Transactions_${formattedDate}`;

    onExport(transactionTableRowsExcel, fileName, headerConfig);
  };

  return (
    <Box>
      {/* Nút Export Excel */}
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleExportExcel}>
          {t('export-excel')}
        </Button>
      </Box>

      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listDepositTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};

export default DepositTransactionContainerTab;
