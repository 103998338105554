import React, { useEffect, useMemo } from 'react';
import TableNormal from 'components/table/TableNormal';
import { DATE_TIME_FORMAT } from 'constants/common';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';

import {
  getListDebtTransactionInBuildingPagingAction,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, showData } from 'utils/common';

import { Box, Button } from '@mui/material';
import useExportExcel from 'hooks/useExportExcel';
import moment from 'moment';

const TRANSACTION_TABLE_HEADER = ['stt', 'debt-price', 'note', 'creation-date'];

const DebtTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useSelector(propertySelector);
  const { listDebtTransactionInBuildingPaging } = useSelector(transactionSelector);

  const { onExport } = useExportExcel();

  useEffect(() => {
    dispatch(
      getListDebtTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  const transactionTableRows = useMemo(() => {
    return listDebtTransactionInBuildingPaging.data.map((transaction) => {
      return [
        showData(transaction.id),
        formatPrice(String(transaction.price)),
        showData(transaction.note),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
      ];
    });
  }, [listDebtTransactionInBuildingPaging.data]);

  const headerConfig = {
    mainHeaders: TRANSACTION_TABLE_HEADER.map((title) => ({
      title: t(title),
    })),
  };

  const handleExportExcel = () => {
    const formattedDate = moment().format('DD:MM:YYYY_HH:MM:SS');

    const fileName = `Debt_Transactions_${formattedDate}`;

    onExport(transactionTableRows, fileName, headerConfig);
  };

  return (
    <Box>
      {/* Nút Export Excel */}
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleExportExcel}>
          {t('export-excel')}
        </Button>
      </Box>

      {/* Bảng hiển thị dữ liệu */}
      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listDebtTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};

export default DebtTransactionContainerTab;
